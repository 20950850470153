@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url(/src/assets/Fonts/Lato-Black.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url(/src/assets/Fonts/Lato-BlackItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(/src/assets/Fonts/Lato-Bold.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url(/src/assets/Fonts/Lato-BoldItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(/src/assets/Fonts/Lato-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url(/src/assets/Fonts/Lato-Italic.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(/src/assets/Fonts/Lato-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url(/src/assets/Fonts/Lato-LightItalic.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url(/src/assets/Fonts/Lato-Thin.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url(/src/assets/Fonts/Lato-ThinItalic.ttf) format('truetype');
}


html {
  -webkit-text-size-adjust: 100%;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 62.5%; /* 1rem = 10px on base font of 16px */
  line-height: 1.5;
  tab-size: 4;
  scroll-behavior: smooth;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: inherit;
  line-height: inherit;
  margin: 0;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
}
h1,
h2,
p,
pre {
  margin: 0;
}
*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
h1,
h2 {
  font-size: inherit;
  font-weight: inherit;
}
a {
  color: inherit;
  text-decoration: inherit;
}
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}
svg {
  display: block;
  shape-rendering: auto;
  text-rendering: optimizeLegibility;
}
pre {
  background-color: rgba(55, 65, 81, 1);
  border-radius: 0.25rem;
  color: rgba(229, 231, 235, 1);
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  overflow: scroll;
  padding: 0.5rem 0.75rem;
}

/** All documents styles */
.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}
